
.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer{
  width: 100%;
  background-color: #FEEBDC;
}
.card {
  color: inherit;
  text-decoration: none;
}

.card:hover,
.card:focus,
.card:active {
  color: black;
  border-color: black;
}

.dropdownmenu{
  width: 100%;
  background-color: #FEEBDC;
}
.dropdownitem{
  color: black;
}

.dropdownitem:hover,
.dropdownitem:focus,
.dropdownitem:active {
  color: #f66640;
  border-color: black;
  background-color: transparent;

}




.hometitle {
  font-size: 5rem;
  font-weight: 900;
  line-height: 0.5;
  width: max-content;
  margin-bottom: 2rem!important;
  text-align: center!important;
  color: #1b1642; 
  font-family: 'poppins,sans-serif';
}

.centerAlign {
  display: flex;
  justify-content: center;
}

.secondaryTitle {
  font-size: 1.125rem!important;
  color: #575a7b!important;
  text-align: center!important;
  font-family: 'poppins,sans-serif';
}

.footerTitle {
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
  font-size: 2.5rem;
}

.mainSecondaryTitle {
  line-height: 1.4;
  margin-bottom: 1rem!important;
  font-size: 1.5rem;
  font-family: poppins,sans-serif;
  font-weight: 900;
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:40px;
	right:40px;
	background-color:#f66640;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
}

.myfloat{
	margin-top:10px;
  font-size: 22px;
}

.myPlusFloat{
	margin-top:10px;
  font-size: 30px;
}

.myModal {
width: 0px;
max-width: 0px;
}

.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 100; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: transparent; /* Black w/ opacity */

}

/* Modal Content/Box */
.modalContent {
  margin: 400px auto; /* 20% from the top and centered */
  border: 0px solid #888;
  width: 0%; 

}


.dropdowntoggle:after {
  display: none;
}

.liftCard:hover{
  position: relative; top: -15px;
  border-radius: 10px ;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}


/* DropDown scroll bar */

#scroll::-webkit-scrollbar {
  width: 10px;
}


/* Track */
#scroll::-webkit-scrollbar-track {
  background: #fceadb; 
}
 
/* Handle */
#scroll::-webkit-scrollbar-thumb {
  background: #f66640; 
  opacity: 0.6;
  height: 5px;
}

/* Handle on hover */
#scrollNav::-webkit-scrollbar-thumb:hover {
  background: #f66640;
  
}

/* Nav scrollNav bar */
#scrollNav::-webkit-scrollbar {
  width: 10px;
}


/* Track */
#scrollNav::-webkit-scrollbar-track {
  background: #fff7ea; 
}
 
/* Handle */
#scrollNav::-webkit-scrollbar-thumb {
  background: #fceadb; 
  opacity: 0.6;
  height: 5px;
}

/* Handle on hover */
#scrollNav::-webkit-scrollbar-thumb:hover {
  background: #fceadb;
  
}

.createstackresult{
  justify-content: center;
}
.createstackresult:hover{
  background-color: #FEEBDC;
  border-radius: 7px;
}

#navdropdown{
  color: white;
  font-weight: bold;
  background-color: red;
}


/* 
#wrapper {
  background-color: #f66640;
  position:absolute;
  height:100%;
  width:100%;
}

.panels {
  background-color: aqua;
  position:relative;
  height:100%;
  min-height:100%;
  width:100%;
}

#a{
 background:#eee;
  position:fixed;
  color:red;
  top:0;
  z-index:-100;
}

#b{
 margin-top:100%;
 background:yellow;
}

#c{
 background:pink;
}

#d{
 background:green;
}

.main {
  padding:0;
  margin:0;
  background:black;
} */

/* .dropdownItem{
  background-color: red;
} */

.dropdownItem:active{
  background-color: transparent;
  color: black;
}

.breadcrumbs > .active,
.breadcrumbs > .active a {
  color: red;
}


.rotateLogo{
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}


.searchBar{
  padding: 10px;
  height: 40px;
  width: 100%;
  border: 1px solid #fdd3bf;
  background-color: #fff7ea;
}

.searchBar:after,
.searchBar:focus
{
  height: 40px;
  width: 100%;
  border: 1px solid none;
  box-shadow: none;

}

.dropdownToogleArrow:after {
  display: none;
  width: 100%;
}

.omniSearchDropdownItem{
  color: black;
}

.omniSearchDropdownItem:hover,
.omniSearchDropdownItem:focus,
.omniSearchDropdownItem:active {
  background-color: transparent;

}